import { Tabs, Divider } from 'antd';
import constants from '../../constants/constants';
import { PlusOutlined, EyeOutlined } from '@ant-design/icons';
import AddSupplier from './AddSupplier';
import ViewSupplier from './ViewSupplier';

const { TabPane } = Tabs;

const Supplier = () => {

  return (
    <div>
      <div className='uppercase font-bold text-xl'>Supplier</div>
      <Divider className='my-3' />
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={<span className='uppercase'><PlusOutlined className='text-xl' />{constants.View}</span>}
          key={1}
        >
          <ViewSupplier />
        </TabPane>
        <TabPane
          tab={<span className='uppercase'><EyeOutlined className='text-xl' />{constants.AddNew}</span>}
          key={2}
        >
          <AddSupplier />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Supplier;