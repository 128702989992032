import { useState, useEffect } from 'react';
import { Table, Button, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import WebLinks from '../api/WebLinks';
import APIUtils from '../api/APIUtils';
import Cookies from 'js-cookie';
import constants from '../constants/constants';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Home = () => {
    const buyerId = Cookies.get(constants.COOKIE_LOGGED_IN_USER_ID)
    const [buyers, setBuyers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [buyerLoader, setBuyerLoader] = useState(false);
    const [supplierLoader, setSupplierLoader] = useState(false);
    // Table start
    const buyersColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'buyerName',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Authorised',
            dataIndex: 'authorised',
            align: 'center',
            render: () => <>
                <CheckOutlined className='text-xl text-lime-500' />
            </>,
        },
        {
            title: 'Allowed Suppliers',
            dataIndex: 'allowedSuppliers',
        },
        {
            title: 'Parser Link',
            dataIndex: 'ParserLink',
        },
        {
            title: 'CPA',
            dataIndex: 'cpa',
        },
        {
            title: 'Job Count',
            dataIndex: 'JobCount',
        },
        {
            title: 'Auto Upload',
            dataIndex: 'autoUpload',
            key: 'autoUpload',
            align: 'center',
            render: () => <>
                <CloseOutlined className='text-xl text-gray-400' />
            </>,
        },
        {
            title: 'Bot Post',
            dataIndex: 'botPost',
            key: 'botPost',
            align: 'center',
            render: () => <>
                <CloseOutlined className='text-xl text-gray-400' />
            </>,
        },
        {
            title: 'API Post',
            dataIndex: 'apiPost',
            align: 'center',
            render: () => <>
                <CheckOutlined className='text-xl text-lime-500' />
            </>,
        },
    ];
    const supplierColumns = [
        {
            title: 'ID',
            dataIndex: 'supplierId',
            width: '5%'
        },
        {
            title: 'Name',
            dataIndex: 'supplierName',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Password',
            dataIndex: 'password',
        },
        {
            title: 'GUID',
            dataIndex: 'guid',
        },
        {
            title: 'Percentage',
            dataIndex: 'cpaPercentage',
        },
        {
            title: 'Generate XML',
            dataIndex: 'generateXML',
            align: 'center',
            render: (value) => <>
                {value ? <CheckOutlined className='text-xl text-lime-500' /> : <CloseOutlined className='text-xl text-red-500' />}
            </>,
        },
    ];
    // Table end

    useEffect(() => {
        fetchBuyers()
        fetchSuppliers()
    }, []);

    const fetchBuyers = async () => {
        setBuyerLoader(true);
        const url = WebLinks.Buyers +
            `?buyerId=${buyerId}&limit=5&pageNo=1&sortBy=name&sortDesc=false`
        const response = await APIUtils.apiCall('GET', url);
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            setBuyers(response.resultObj.data);
            setBuyerLoader(false);
        } else {
            setBuyers([]);
            setBuyerLoader(false);
            message.error(response.resultObj.message);
        }
    }

    const fetchSuppliers = async () => {
        setSupplierLoader(true);
        const url = WebLinks.Suppliers +
            `?limit=5&pageNo=1&sortBy=Id&sortDesc=false`
        const response = await APIUtils.apiCall('GET', url);
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            setSuppliers(response.resultObj.data);
            setSupplierLoader(false);
        } else {
            setSuppliers([]);
            setSupplierLoader(false);
            message.error("Suppliers not found");
        }
    }

    return (
        <div>
            <div className='uppercase font-bold text-xl'>Buyers</div>
            <div className='mt-3 overflow-x-auto'>
                <Table
                    loading={buyerLoader}
                    columns={buyersColumns}
                    dataSource={buyers}
                    loading={buyerLoader}
                    pagination={false}
                    footer={() => (
                        <div className='text-right'>
                            <Link to='/buyer'>
                                <Button type='link'>{constants.MoreBtn}</Button>
                            </Link>
                        </div>
                    )}
                />
            </div>
            <div className='uppercase font-bold text-xl'>Suppliers</div>
            <div className='mt-3 overflow-x-auto'>
                <Table
                    supplierLoader={supplierLoader}
                    columns={supplierColumns}
                    dataSource={suppliers}
                    loading={supplierLoader}
                    pagination={false}
                    footer={() => (
                        <div className='text-right'>
                            <Link to='/supplier'>
                                <Button type='link'>{constants.MoreBtn}</Button>
                            </Link>
                        </div>
                    )} />
            </div>
        </div>
    );
};

export default Home;
