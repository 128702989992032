import React from "react";
import { Switch, Route } from "react-router-dom";
import Layouts from "../views/common/Layout";
import { RouteWithLayout } from "./helper";
import Login from "../views/common/cred/Login";
// import Profile from "../views/common/cred/Profile";
import Home from "../views/Home";
import Buyers from "../views/Buyers";
import Buyer from "../views/buyer/Buyer";
import Supplier from "../views/supplier/Supplier";
import Images from "../views/images/Images";
import Users from "../views/Users";
import Companies from "../views/companies/Companies";
import EditEmp from "../views/buyer/EditEmp";
import AddBuyer from "../views/buyer/AddBuyer";
import AddSupplier from "../views/supplier/AddSupplier";
import IndeedData from "../views/Indeed";

const Routes = ({ location, ...rest }) => {
	return (
		<div>
			<Switch>
				<Route
					exact
					path="/login"
					component={Login}
				/>
				{/* <Route
					exact
					path="/"
					component={Login}
				/> */}
				{/* <RouteWithLayout
					exact
					path="/profile"
					layout={Layouts}
					location={location}
					component={Profile}
					{...rest}
				/> */}
				<RouteWithLayout
					exact
					path="/"
					layout={Layouts}
					location={location}
					component={Home}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/buyer"
					layout={Layouts}
					location={location}
					component={Buyer}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/supplier"
					layout={Layouts}
					location={location}
					component={Supplier}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/images"
					layout={Layouts}
					location={location}
					component={Images}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/users"
					layout={Layouts}
					location={location}
					component={Users}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/companies"
					layout={Layouts}
					location={location}
					component={Companies}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-buyer"
					layout={Layouts}
					location={location}
					component={AddBuyer}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/add-buyer"
					layout={Layouts}
					location={location}
					component={AddBuyer}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-supplier"
					layout={Layouts}
					location={location}
					component={AddSupplier}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-employer"
					layout={Layouts}
					location={location}
					component={EditEmp}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/indeed"
					layout={Layouts}
					location={location}
					component={IndeedData}
					{...rest}
				/>
			</Switch>
		</div>
	);
};
export default Routes;
