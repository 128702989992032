import { useState, useEffect } from 'react';
import { Input, Table, message, Space, Image, Tooltip  } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';

const { Search } = Input;

const ViewImages = () => {
	const [loader, setLoader] = useState(false);
	const [images, setImages] = useState([]);
	const [total, setTotal] = useState();
	const [imageURL, setImageURL] = useState('');
	const [visible, setVisible] = useState(false)
	const [tableConfig, setTableConfig] = useState({
		pageNo: 1,
		limit: 50,
		search: ''
	});

	// Table start
	const ViewImagesColumns = [
		{
			title: 'ID',
			dataIndex: 'id',
			rowScope: 'row',
		},
		{
			title: 'Image Name',
			dataIndex: 'imageName',
			key: 'imageName',
		},
		{
			title: 'File Name',
			dataIndex: 'namedFiles',
			key: 'namedFiles',
			render: (_, data) => <>
				{<Space>
					<CopyOutlined onClick={() => { navigator.clipboard.writeText(data.namedFiles) }} />
					<Tooltip title="View Image">
						<span className='cursor-pointer' onClick={() => { setImageURL(data.namedFiles); setVisible(true) }}>{data.namedFiles}</span>
					</Tooltip>
				</Space>}
			</>,
		},
		{
			title: 'File Type',
			dataIndex: 'fileType',
			key: 'fileType',
		}
	];
	// Table end

	useEffect(() => {
		fetchImages()
	}, [tableConfig]);

	useEffect(() => {
		if(!visible) {
			setImageURL('')
		}
	}, [visible]);

	const fetchImages = async () => {
		setLoader(true);
		const queryParams = new URLSearchParams(tableConfig).toString();
		const url = `${WebLinks.Images}?${queryParams}`;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setImages(response.resultObj.data);
			setTotal(response.resultObj.getCount)
			setLoader(false);
		} else {
			setImages([]);
			setLoader(false);
			message.error("Images not found");
		}
	}

	const onSearch = async (value) => {
		setTableConfig((prevConfig) => ({
			...prevConfig,
			search: value
		}));
	}

	const onChangeTable = (pagination) => {

		setTableConfig((prevConfig) => ({
			...prevConfig,
			pageNo: pagination
		}));

	};


	return (
		<div>
			<div className='grid place-content-end mb-4'>
				<Search
					placeholder="Search"
					allowClear
					onSearch={onSearch}
					enterButton
					className='w-60'
				/>
			</div>
			<div className='overflow-x-auto'>
				<Table
					loading={loader}
					columns={ViewImagesColumns}
					dataSource={images}
					pagination={{
						pageSizeOptions: ["25", "50", "100"],
						total: total,
						current: tableConfig.pageNo,
						pageSize: tableConfig.limit,
						onChange: onChangeTable
					}}
				/>
			</div>
			<Image
				width={200}
				style={{
					display: 'none',
				}}
				src={imageURL}
				preview={{
					visible,
					src: imageURL,
					onVisibleChange: (value) => {
						setVisible(value);
					},
				}}
			/>
		</div>
	);
};

export default ViewImages;