/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter  } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
//Route Dictionary
import RouteManager from "./routes/Routes";
//Parent Styles
import "./index.css";
import "./App.scss";

const App = () => (
	<BrowserRouter >
		<RouteManager/>
	</BrowserRouter>
);
ReactDOM.render(<App/>, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
