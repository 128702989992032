import { useState } from 'react';
import { Tabs, Divider } from 'antd';
import constants from '../../constants/constants';
import { PlusOutlined, EyeOutlined } from '@ant-design/icons';
import AddBuyer from './AddBuyer';
import ViewBuyer from './ViewBuyer';

const { TabPane } = Tabs;

const Buyer = () => {
  const [activeTabKey, setActiveTabKey] = useState('1'); // Initialize with default active tab

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const handleSaveSuccess = () => {
    setActiveTabKey(1); // Switch to the "View" tab
  };

  return (
    <div>
      <div className='uppercase font-bold text-xl'>Buyer</div>
      <Divider className='my-3' />
      <Tabs defaultActiveKey={activeTabKey} onChange={handleTabChange} activeTabKey={activeTabKey}>
        <TabPane
          tab={<span className='uppercase'><EyeOutlined className='text-xl' />{constants.View}</span>}
          key={1}
        >
          <ViewBuyer activeTabKey={activeTabKey} />
        </TabPane>
        <TabPane
          tab={<span className='uppercase'><PlusOutlined className='text-xl' />{constants.AddNew}</span>}
          key={2}
        >
          <AddBuyer onSaveSuccess={handleSaveSuccess} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Buyer;