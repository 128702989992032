import React, { useState } from "react";
import { Layout, Input, Form, Row, Col, Button, Image, message } from 'antd';
import constants from '../../../constants/constants';
import logo from '../../../applygateway-logo.svg';
import APIUtils from '../../../api/APIUtils';
import WebLinks from '../../../api/WebLinks';
import Cookies from "js-cookie";
import { useAuth } from '../../../services/AuthService';
// import bgvideo from '../../../assets/headvid-reduced.mp4';

const { Header, Content } = Layout;

const Login = () => {
	const { loginAuth } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [alertType, setalertType] = useState('');
	const [showAlert, setShowAlert] = useState(false);
	const onFinish = async (values) => {
		setIsLoading(true)
		let body = JSON.stringify({ userName: values.email, password: values.password });
		let response = await APIUtils.apiCall("POST", WebLinks.Login, body, false);
		if (response.isCatch) {
			//show error message
			setIsLoading(false);
		} else {
			await onLoginAPISucess(response.resultObj)
		}
	};
	const onLoginAPISucess = (res) => {
		if (res.statuscode === 2000) {
			loginAuth(res.data.accessToken, true).then(async () => {
				Cookies.set(constants.COOKIE_LOGGED_IN_USER_ID, res.data.id, { path: '/', expires: constants.COOKIE_EXPIRY_DAYS });
				window.location.href = '/'
			});
		} else {
			//showMessage(res && res.Message ? res.Message : constants.ERROR_MESSAGE, 'error');
			message.error(res.message)
			setIsLoading(false);
		}
	};

	return (
		<div className='bg-img'>
			{/* <video loop autoPlay muted className="video-bg">
				<source src={bgvideo} type="video/mp4" />
				Your browser does not support HTML5 video.
			</video> */}
			<Layout className='h-screen'>
				<Header className='container mx-auto bg-transparent px-3 lg:px-10'>
					<Image src={logo} alt="logo" preview={false} width={340} height={90} className='mt-3' />
				</Header>
				<Content className='container mx-auto px-3 lg:px-10 flex items-center'>
					<Row className="w-full">
						<Col xs={24} lg={8}>
							<Form layout="vertical" requiredMark={false} onFinish={(e) => onFinish(e)}>
								<Form.Item
									label={<span className='uppercase'>{constants.Email}</span>}
									name="email"
									rules={[
										{
											type: 'email',
											required: true,
											message: constants.LoginEmailValid,
										},
									]}
								>
									<Input autoFocus size="large" placeholder={constants.Email} />
								</Form.Item>
								<Form.Item
									label={<span className='uppercase'>{constants.Password}</span>}
									name="password"
									rules={[
										{
											required: true,
											message: constants.LoginPasswordValid,
										},
									]}
								>
									<Input.Password
										placeholder={constants.Password}
										size="large"
									/>
								</Form.Item>
								<Form.Item>
									<Button loading={isLoading} size="large" block htmlType="submit" className='warning-btn'>
										{constants.Login}
									</Button>
								</Form.Item>
							</Form>
						</Col>
					</Row>
				</Content>
			</Layout>
		</div>
	);
};

export default Login;