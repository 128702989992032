import { Form, Input, Col, InputNumber, Row, Slider, Select, Switch, Space, Button, message, Typography, Spin, Divider } from 'antd';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';

const { Text } = Typography

const AddSupplier = (props) => {
	const history = useHistory();
	const [loader, setLoader] = useState(false);
	const [countryList, setCountryList] = useState([]);
	const [supplierId, setSupplierId] = useState(null);
	const [form] = Form.useForm();
	const onCancel = () => {
		form.resetFields();
		history.push('/supplier');
	};
	// useEffect(() => {
	// 	console.log("props", props)
	// 	if (props.supplierId) {
	// 		setSupplierId(props.supplierId)
	// 		fetchSupplierDetail(props.supplierId)
	// 	}

	// }, [props]);
	useEffect(() => {
		if (props.location?.state?.supplierId) {
			setSupplierId(props.location.state?.supplierId)
			fetchSupplierDetail(props.location.state?.supplierId)
		}
		// else {
		// 	const result = {
		// 		cpaPercentage: 0
		// 	};
		// 	form.setFieldsValue(result);
		// }

	}, [props]);

	useEffect(() => {
		countryFetch();
	}, []);

	const countryFetch = async () => {
		const response = await APIUtils.apiCall('GET', WebLinks.CountryList);
		if (response.resultObj?.statusCode === "2000") {

			setCountryList(response.resultObj.data);
		}
	};

	const fetchSupplierDetail = async (id) => {
		setLoader(true)
		const response = await APIUtils.apiCall('GET', WebLinks.GetSupplierDetail + id);
		if (response.resultObj?.statusCode === "2000") {
			form.setFieldsValue(response.resultObj.data)
		}
		setLoader(false)
	}

	const onChange = (newValue) => {
		form.setFieldsValue({
			cpaPercentage: newValue
		})
		//setInputValue(newValue);
	};

	const onFinish = async (values) => {

		let type = 'POST'
		if (supplierId) {
			type = 'PUT'
			values.supplierId = supplierId
		}
		values.guid = '5deac785-ba9b-4427-b4c6-0d0bdee4c4a7';
		const url = WebLinks.Suppliers
		const response = await APIUtils.apiCall(type, url, JSON.stringify(values));
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			message.success(response.resultObj.message)
			history.push({
				pathname: '/supplier'
			});
		} else {
			message.error(response.resultObj.message)
		}
	};

	return (
		<Spin spinning={loader}>
			{supplierId &&
				<>
					<div className='uppercase font-bold text-xl'>Edit Supplier</div>
					<Divider className='my-3' />
				</>}
			<Form
				form={form}
				name="form_in_modal"
				layout="vertical"
				onFinish={onFinish}
				initialValues={{
					apiKey: "b868b0ec479e43108d12",
					SupplierGUID: "5deac785-ba9b-4427-b4c6-0d0bdee4c4a7",
					cpaPercentage: 0,
					generateXML: true,
					allowReports: true,
					clickRedirect: false,
					forceInternal: false,
					budget: false,
					freeBoard: false,
					cpaBidding: false,
					singlePostApi: false
				}}
			>
				<div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
					<Form.Item
						name="supplierName"
						label="Supplier Name"
						rules={[
							{
								required: true,
								message: 'Required field',
							},
						]}
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="displayName"
						label="Display Name"
						rules={[
							{
								required: true,
								message: 'Required field',
							},
						]}
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="email"
						label="Email"
						className="mb-0"
						rules={[
							{
								required: true,
								type: 'email',
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="password"
						label="Password"
						className="mb-0"
						rules={[
							{
								required: true,
								type: 'password',
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="feedLimit"
						label="Feed Limit"
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="apiKey"
						label="API Key"
						className="mb-0"
					>
						<Text strong>b868b0ec479e43108d12</Text>
					</Form.Item>
					<Form.Item
						name="guid"
						label="Supplier GUID"
						className="mb-0"
					>
						<Text strong>5deac785-ba9b-4427-b4c6-0d0bdee4c4a7</Text>
					</Form.Item>

					<Row gutter={[8, 8]}>
						<Col span={18}>
							<div className='mb-4'><label>Supplier Percentage</label></div>
							<Slider
								onChange={onChange}
							/>
						</Col>
						<Col span={6} className='pt-0.5'>
							<Form.Item
								name="cpaPercentage"
								className="mb-0"
							>
								<InputNumber
									onChange={onChange}
									addonAfter="%"
									className='mt-7'
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[8, 8]}>
						<Col span={18}>
							<Form.Item
								name="country"
								label="Country"
								className="mb-0"
							>
								<Select
									showSearch
									placeholder="Select Country"
									className='w-full'
								>
									{countryList.map((country) => (
										<Select.Option key={country.countryCode} value={country.countryCode}>
											{country.county}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								name="currency"
								label="Currency"
							>
								<Select
									options={[
										{
											value: 'GBP',
											label: 'GBP',
										},
										{
											value: 'EUR',
											label: 'EUR',
										},
										{
											value: 'USD',
											label: 'USD',
										},
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						name="logoURL"
						label="Logo URL"
						className="mb-0"
					>
						<Input />
					</Form.Item>

				</div>
				<div className="grid xs:grid-cols-1 lg:grid-cols-4 gap-4">
					<Form.Item
						name="generateXML"
						label="Generate XML"
						valuePropName="checked"
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="allowReports"
						label="Allow Reports"
						valuePropName="checked"
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="clickRedirect"
						label="Click Redirect"
						valuePropName="checked"
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="forceInternal"
						label="Force Internal"
						valuePropName="checked"
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						label="Budget"
						name="budget"
						valuePropName="checked"
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						label="Free Board"
						name="freeBoard"
						valuePropName="checked"
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						label="CPA Bidding"
						name="cpaBidding"
						valuePropName="checked"
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						label="Single Post API"
						name="singlePostApi"
						valuePropName="checked"
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
				</div>
				<div className='grid place-content-end'>
					<Space>
						<Form.Item>
							<Button className='uppercase' htmlType="button" onClick={onCancel}>
								Cancel
							</Button>
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Save
							</Button>
						</Form.Item>
					</Space>
				</div>
			</Form>
		</Spin>
	);
};

export default AddSupplier;