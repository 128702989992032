import { useState, useEffect } from 'react';
import { Form, Input, Space, Button, Divider, InputNumber, Card, message, Spin } from 'antd';
import { CountryDropdown } from 'react-country-region-selector';
import constants from '../../constants/constants';
import { Link } from "react-router-dom";
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';

const { TextArea } = Input;

const EditEmp = (props) => {
	const [form] = Form.useForm();
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		if (props?.location?.state?.buyerId) {
			fetchBuyerEmployer(props?.location?.state?.buyerId)
		}
	}, [props]);

	const fetchBuyerEmployer = async (id) => {
		setLoader(true);
		const url = `${WebLinks.GetBuyerEmployer}${id}`;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			form.setFieldsValue(response.resultObj.data)
			setLoader(false);
		} else {
			setLoader(false);
		}
	};

	const onReset = () => {
		form.resetFields();
	};

	const submitEmployer = async (values) => {
		values.buyerId = props?.location?.state?.buyerId
		const url = WebLinks.SubmitBuyerEmployer
		const response = await APIUtils.apiCall('POST', url, JSON.stringify(values));
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			message.success(response.resultObj.message)
		} else {
			message.error(response.resultObj.message)
		}
	}

	return (
		<Spin spinning={loader}>
			<div className='uppercase font-bold text-xl'>Edit Employer</div>
			<Divider className='my-3' />
			<Form
				form={form}
				name="form_in_modal"
				layout="vertical"
				onFinish={submitEmployer}
			>
				<div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
					<Form.Item
						name="companyTagLine"
						label="Company Tag Line"
						className="mb-0"
					>
						<TextArea
							rows={2}
						/>
					</Form.Item>
					<Form.Item
						name="companyProfile"
						label="Company Profile"
						className="mb-0"
					>
						<TextArea
							rows={2}
						/>
					</Form.Item>
					<Form.Item
						name="companySize"
						label="Company Size"
						className="mb-0"
					>
						<InputNumber className="w-full" />
					</Form.Item>
					<Form.Item
						name="founded"
						label="Founded"
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="headquarters"
						label="Headquarters"
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="ceo"
						label="CEO"
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="whyWorkHere"
						label="Why Work Here"
						className="mb-0"
					>
						<TextArea
							rows={1}
						/>
					</Form.Item>
					<Form.Item
						name="companyVideo"
						label="Company Video URL"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="companyPhoto1"
						label="Company Photo 1"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="companyPhoto2"
						label="Company Photo 2"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="companyPhoto3"
						label="Company Photo 3"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="staffTestimonial1"
						label="Staff Testimonial 1"
						className="mb-0"
					>
						<TextArea
							rows={1}
						/>
					</Form.Item>
					<Form.Item
						name="staffTestimonial1Photo"
						label="Staff Testimonial Photo 1"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="staffTestimonial2"
						label="Staff Testimonial 2"
						className="mb-0"
					>
						<TextArea
							rows={1}
						/>
					</Form.Item>
					<Form.Item
						name="staffTestimonial2Photo"
						label="Staff Testimonial Photo 2"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="staffTestimonial3"
						label="Staff Testimonial 3"
						className="mb-0"
					>
						<TextArea
							rows={1}
						/>
					</Form.Item>
					<Form.Item
						name="staffTestimonial3Photo"
						label="Staff Testimonial Photo 3"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="staffVideo1"
						label="Staff Video URL 1"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="staffVideo2"
						label="Staff Video URL 2"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="ourValues"
						label="Our Values"
						className="mb-0"
					>
						<TextArea
							rows={1}
						/>
					</Form.Item>
					<Form.Item
						name="ourValues1"
						label="Our Values 1"
						className="mb-0"
					>
						<TextArea
							rows={1}
						/>
					</Form.Item>
					<Form.Item
						name="ourValues2"
						label="Our Values 2"
						className="mb-0"
					>
						<TextArea
							rows={1}
						/>
					</Form.Item>
					<Form.Item
						name="ourValues3"
						label="Our Values 3"
						className="mb-0"
					>
						<TextArea
							rows={1}
						/>
					</Form.Item>
					<Form.Item
						name="ourValues4"
						label="Our Values 4"
						className="mb-0"
					>
						<TextArea
							rows={1}
						/>
					</Form.Item>
					<Form.Item
						name="ourValues5"
						label="Our Values 5"
						className="mb-0"
					>
						<TextArea
							rows={1}
						/>
					</Form.Item>
					<Form.Item
						name="ourValues6"
						label="Our Values6"
						className="mb-0"
					>
						<TextArea
							rows={1}
						/>
					</Form.Item>
					<Form.Item
						name="careerDevelopment"
						label="Career Development"
						className="mb-0"
					>
						<TextArea
							rows={1}
						/>
					</Form.Item>
				</div>
				<div className='grid gap-2 my-3'>
					<Card>
						<Form.Item
							name="question1"
							label="Question 1"
							className="mb-2"
						>
							<TextArea
								rows={1}
							/>
						</Form.Item>
						<Form.Item
							name="question1Answer"
							label="Answer 1"
							className="mb-0"
						>
							<TextArea
								rows={1}
							/>
						</Form.Item>
					</Card>
					<Card>
						<Form.Item
							name="question2"
							label="Question 2"
							className="mb-2"
						>
							<TextArea
								rows={1}
							/>
						</Form.Item>
						<Form.Item
							name="question2Answer"
							label="Answer 2"
							className="mb-0"
						>
							<TextArea
								rows={1}
							/>
						</Form.Item>
					</Card>
					<Card>
						<Form.Item
							name="question3"
							label="Question 3"
							className="mb-2"
						>
							<TextArea
								rows={1}
							/>
						</Form.Item>
						<Form.Item
							name="question3Answer"
							label="Answer 3"
							className="mb-0"
						>
							<TextArea
								rows={1}
							/>
						</Form.Item>
					</Card>
					<Card>
						<Form.Item
							name="question4"
							label="Question 4"
							className="mb-2"
						>
							<TextArea
								rows={1}
							/>
						</Form.Item>
						<Form.Item
							name="question4Answer"
							label="Answer 4"
							className="mb-0"
						>
							<TextArea
								rows={1}
							/>
						</Form.Item>
					</Card>
					<Card>
						<Form.Item
							name="question5"
							label="Question 5"
							className="mb-2"
						>
							<TextArea
								rows={1}
							/>
						</Form.Item>
						<Form.Item
							name="question5Answer"
							label="Answer 5"
							className="mb-0"
						>
							<TextArea
								rows={1}
							/>
						</Form.Item>
					</Card>
				</div>

				<div className='grid place-content-end'>
					<Space>
						<Link to="/buyer">
							<Button className='uppercase' htmlType="button">
								Cancel
							</Button>
						</Link>
						<Form.Item className='mb-0'>
							<Button className='uppercase' htmlType="button" onClick={onReset}>
								Reset
							</Button>
						</Form.Item>
						<Form.Item className='mb-0'>
							<Button type="primary" htmlType="submit">
								{constants.UpdateBtn}
							</Button>
						</Form.Item>
					</Space>
				</div>
			</Form>
		</Spin>
	);
};

export default EditEmp;