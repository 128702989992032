import Cookies from "js-cookie";
import constants from "../constants/constants";
import { useHistory } from "react-router-dom";

export function useAuth() {
  const history = useHistory();
  return {
    isUserAuthed(){
        return Cookies.get(constants.COOKIE_TOKEN) || false;
    },
    loginAuth(accessToken, rememberMe) {
      return new Promise((res) => {
        if(rememberMe){
            //TODO: remove the expiry date keep the cookies unless user logs in
          Cookies.set(constants.COOKIE_TOKEN, accessToken, {path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
        }else{
          Cookies.set(constants.COOKIE_TOKEN, accessToken)
        }
        res();
      });
    },
    logoutAuth() {
      return new Promise((res) => {
        Cookies.remove(constants.COOKIE_TOKEN);
        Cookies.remove(constants.COOKIE_LOGGED_IN_USER_ID);
        Cookies.remove(constants.COOKIE_BUYER_SEARCH);
        res();
      });
    },
    redirectToLogin() {
      // Redirect to the login page
      history.push('/login');
    },
  };
}