import React from 'react';
import { Layout, Image, Menu } from "antd";
import logo from '../../applygateway-logo.svg';
// import { Link } from "react-router-dom";
import { useAuth } from '../../services/AuthService';

const { Header } = Layout;

const Headers = () => {
  const { logoutAuth } = useAuth()
  return (
    <Header className='flex justify-between items-center bg-transparent container mx-auto mt-3 px-3 lg:px-0 h-min' id='top'>
      <div className='flex items-center gap-4 logo ml-8 lg:ml-0'>
        <Image src={logo} preview={false} width={340} height={90} alt="logo" />
      </div>
      <Menu className='bg-transparent text-lg font-semibold' mode="horizontal" disabledOverflow={true}>
        {/* <Menu.Item key="profile">
          <Link to="/profile">
            Profile
          </Link>
        </Menu.Item> */}
        <Menu.Item key="logout" onClick={() =>
          logoutAuth().then(() => {
            window.location.href = '/login'
          })}>
          Logout
        </Menu.Item>
      </Menu>
    </Header>
  );
};

export default Headers;
